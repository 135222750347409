import { SuggestionButton } from "../suggestionButton/SuggestionButton";
import "./suggestions.scss";

export const Suggestions = ({ askQuestion }) => {
  const handleSuggestionClick = (e) => {
    askQuestion(e.target.value);
  };

  return (
    <div className="suggestions-container">
      <h3>
        <b>HELLO!</b>
      </h3>
      <p>
        I’m an artificial intelligence (AI) language model. I’m not a person,
        but I am equipped to answer any questions you have about how to take
        civic actions and vote in your community. I will remember what you said
        earlier in a conversation and provide any clarifications and follow-up
        as needed.
      </p>
      <p>Please note that I may occasionally:</p>
      <div className="chat-issues">
        <div>1. Generate incorrect information </div>
        <div>2. Provide biased content </div>
        <div>3. Not know the answer to a world event after 2021</div>
        </div>
      <p>
        <b>Not sure what to ask? Here are some suggestions:</b>
      </p>

      <div className="suggestion-buttons">
        <SuggestionButton
          handleSuggestionClick={handleSuggestionClick}
          value="“How do I register to vote in my state?”"
        />
        <SuggestionButton
          handleSuggestionClick={handleSuggestionClick}
          value="“What’s the voter registration deadline?”"
        />
        <SuggestionButton
          handleSuggestionClick={handleSuggestionClick}
          value="“When’s the next election in my area?”"
        />
        <SuggestionButton
          handleSuggestionClick={handleSuggestionClick}
          value="“Who’s running for office in my district?”"
        />
      </div>
    </div>
  );
};
