import { useMutation } from "@tanstack/react-query";
import { EventSourcePolyfill } from "event-source-polyfill";
import he from "he";
import { useState } from "react";

export const useSSE = () => {
  const [sseData, setData] = useState("");
  const [done, setDone] = useState(false);

  const saveHistoryMutation = useMutation({
    mutationKey: ["saveHistory"],
    mutationFn: ({ token, chatHistory }) =>
      fetch(`${process.env.REACT_APP_API_URL}/store`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          bot: sseData,
          chat_history: chatHistory,
        }),
      }).then((res) =>
        res.json().then((data) => {
          console.log("history saved: ", data);
        })
      ),
  });

  const saveHistory = (token, chatHistory) => {
    saveHistoryMutation.mutate({token, chatHistory});
  };

  const stream = (token, chatHistory, chatId) => {
    setData("");
    setDone(false);
    const addr = `/stream?chat_history=${chatHistory}&id=${encodeURIComponent(
      chatId
    )}`;
    const fullAddr = `${process.env.REACT_APP_API_URL}${addr}`;
    const eventSourceHeaders = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const sse = new EventSourcePolyfill(fullAddr, eventSourceHeaders);

    function getRealtimeData(data) {
      // process the data here,
      // then pass it to state to be rendered
      if (data === "[DONE]") {
        saveHistory(token, chatHistory)
        setDone(true);
        sse.close();
        return;
      }
      const txt = JSON.parse(data).choices[0].delta.content;
      if (txt !== undefined) {
        const formatted = he.decode(txt);
        setData(
          (sseData) => sseData + formatted.replace(/(?:\r\n|\r|\n)/g, " <br>")
        );
      }
    }

    sse.onmessage = (e) => {
      getRealtimeData(e.data);
    };
    sse.onerror = (e) => {
      // error log here
      console.error(e);
      setDone(true);
      sse.close();
    };
  };

  return { sseData, done, stream };
};
