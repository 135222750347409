import "./login.scss";
import { useState } from "react";
import { Logo } from "../logo/logo";
import { useAuth } from "../../hooks/useAuth";

export const Login = () => {
  const { login, error, isError, isLoading } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    login({
      email,
      password,
    });
  };

  return (
    <div className="login-container container">
      <Logo />
      <h1>CHATSOMETHING</h1>
      <p>Enter the password you received in your email inbox below.</p>
      <div className={`error-container ${isError ? "visible" : ""}`}>
        {isError && !error.errors && error.message && <p>{error.message}</p>}
        {isError && error.errors && Object.values(error.errors).map(err => <p key={err}>{err}</p>)}
      </div>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label>Email Address* </label>
            <input
              type="text"
              className="text-input"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="E-mail"
            />
          </div>
          <div className="input-container">
            <label>Password* </label>
            <input
              type="password"
              name="password"
              className="text-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="•••••••••"
            />
          </div>
          <div className="button-container">
            <input type="submit" className="button" disabled={isLoading} value="Log In"/>
          </div>
        </form>
      </div>
      <p className="text-center"><i>Creating an account means you agree to the Terms of Service​, Privacy Policy and our default Notification Settings. DoSomething.org will send you communications; you may change your preferences in your account settings.</i></p>
    </div>
  );
};
