import './chatBubble.scss';
import UserIcon from '../../assets/images/DSUserAvatar.svg';
import BotIcon from '../../assets/images/DSChatAvatar.svg';

export const ChatBubble = ({message}) => {
  const avatarSrc = message.owner === 'bot' ? BotIcon : UserIcon; 

  return <div className={`chat-bubble-container ${message.owner}`}>
    <div className="avatar">
      <img src={avatarSrc} alt={`${message.owner} avatar`} />
    </div>
    <div className="chat-message"><div dangerouslySetInnerHTML={{__html: message.text}} /></div>
  </div>
}