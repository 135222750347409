import { createContext, useContext, useState } from "react";
import { uuidv4 } from "../services/cryptoService";
import UserContext from "./UserContext";

const ChatContext = createContext();

export const ChatProvider = ({children}) => {
  const [currentUser] = useContext(UserContext);
  const [startedConversation, setStartedConversation] = useState(false);
  const generateUserPrefix = () => {
    const userPrefix = currentUser.name.replace(/\s/g, "");
    return `${userPrefix}_${uuidv4()}`;
  }
  const [chatId, setChatId] = useState(generateUserPrefix());

  const resetChatId = () => {
    setChatId(generateUserPrefix());
  }

return <ChatContext.Provider value={{startedConversation, setStartedConversation, chatId, resetChatId}}>
  {children}
</ChatContext.Provider>
}

export default ChatContext;