import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useState } from "react";
import ChatContext from "../contexts/ChatContext";
import UserContext from "../contexts/UserContext";
import { useSSE } from "./useSSE";

export const useChat = () => {
  const urify = useCallback((text) => {
    const urlRegex = /(\bhttps?:\/\/\S+[.,]?\b)/g;
    return text.replace(urlRegex, '<a href="$1" target="_new">$1</a>');
  }, []);

  const [messages, setMessages] = useState([]);
  const [currentUser] = useContext(UserContext);
  const { chatId, resetChatId } = useContext(ChatContext);
  const [isLoading, setIsLoading] = useState(false);
  const { stream, sseData, done } = useSSE();

  const resetChat = () => {
    setMessages([]);
    resetChatId();
  };

  const addMessageToList = (message) => {
    setMessages((state) => [
      ...state,
      { owner: message.owner, text: message.text },
    ]);
  };

  const askChatMutation = useMutation({
    mutationKey: ["send"],
    mutationFn: (message) =>
      fetch(`${process.env.REACT_APP_API_URL}/send`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${currentUser.token}`,
        },
        body: JSON.stringify({ message, user_id: chatId }),
      })
        .then((res) =>
          res.json().then((data) => {
            if (data.id) {
              stream(currentUser.token, data.id, chatId);
            } else {
              handleError(data.message);
            }
          })
        )
        .catch((e) => {
          handleError(e);
        }),
  });

  useEffect(() => {
    if (done) {
      addMessageToList({ owner: "bot", text: urify(sseData) });
    }
  }, [done, sseData, urify]);

  useEffect(() => {
    if (done) {
      setIsLoading(false);
    }
  }, [done]);

  const askChat = (message) => {
    setIsLoading(true);
    return askChatMutation.mutate(message);
  };

  const handleError = (e) => {
    console.error("Error occured when sending a message: ", e);
    setIsLoading(false);
  };

  return {
    askChat,
    sseData,
    isLoading,
    isDone: done,
    resetChat,
    messages,
    addMessageToList,
  };
};
