import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import UserContext from "../contexts/UserContext";

export const useAuth = () => {
  const [ currentUser, setCurrentUser ] = useContext(UserContext);

  const {mutate, isSuccess, error, isError, isLoading} = useMutation({
    mutationKey: ["login"],
    mutationFn: (user) =>
      fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {method: "POST", body: JSON.stringify(user)}).then((res) =>
        res.json().then((obj) => {
          if (obj.status) {
            return Promise.resolve(obj);
          } else {
            return Promise.reject(obj);
          }
        })
      ),
    onSuccess: (res) => {
      const {name, token} = res; 
        setCurrentUser({name, token});
        localStorage.setItem('user', JSON.stringify({name, token}));
    },
  });

  const login = (user) => {
    mutate(user);
  };

  const logout = () => {
    setCurrentUser({});
    localStorage.setItem('user', '{}');
  };

  const isAuthenticated = () => {
    const user = localStorage.getItem('user');
    if (!user) {
      return {}
    }
    return JSON.parse(user);
  };

  return { currentUser, login, logout, isAuthenticated, isError, error, isSuccess, isLoading };
};
