import "./chatContainer.scss";
import { useState } from "react";
import { useChat } from "../../hooks/useChat";
import { Header } from "../header/Header";
import { Suggestions } from "../suggestions/Suggestions";
import { MessagesList } from "../messagesList/MessagesList";
import { useAuth } from "../../hooks/useAuth";

export const ChatContainer = () => {
  const [question, setQuestion] = useState("");
  const { logout } = useAuth();
  const {
    askChat,
    isLoading,
    sseData,
    isDone,
    resetChat,
    messages,
    addMessageToList,
  } = useChat();
  const showDynamicBubble = isLoading && !isDone;
  const scrollBottom = messages.length > 0 ? "scroll-bottom" : "";

  const handleSubmit = (e) => {
    e.preventDefault();
    if (showDynamicBubble) {
      return;
    }
    askQuestion(question);
  };

  const askQuestion = (_question) => {
    addMessageToList({ owner: "user", text: _question });
    askChat(_question);
    setQuestion("");
  };

  return (
    <div className="chat-container container">
      <div className="chat-header">
        <Header />
        <div className="functions">
          <input
            type="button"
            className="button secondary"
            value="New Chat"
            disabled={showDynamicBubble}
            onClick={resetChat}
          />
          <input
            type="button"
            className="button secondary"
            value="Log out"
            disabled={showDynamicBubble}
            onClick={logout}
          />
        </div>
      </div>
      <div className="chat-inner">
        <div className="scroll-container">
          <div className={`scroll-inner ${scrollBottom}`}>
            {messages.length === 0 ? (
              <Suggestions askQuestion={askQuestion} />
            ) : null}
            {messages.length > 0 ? (
              <MessagesList
                messages={messages}
                dynamicMessage={{ owner: "bot", text: sseData }}
                showDynamicBubble={showDynamicBubble}
              />
            ) : null}
          </div>
        </div>
        <div className="question-container">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="text-input"
              required
              placeholder="Send your message here..."
            />
            <input
              type="submit"
              value="Send"
              className="button primary"
              disabled={showDynamicBubble}
            />
          </form>
          <p className="chat-info">
            This is placeholder text about this being an AI chat tool and not a
            real person. This will also include information about how it will
            use the information you share with it ethically in and accordance
            with DS’s Privacy Policy.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};
