import "./messagesList.scss";
import { ChatBubble } from "../chatBubble/ChatBubble";

export const MessagesList = ({
  messages,
  dynamicMessage,
  showDynamicBubble,
}) => {
  return (
    // <div>
    <div className="messages-list-container">
      <div>
        {messages.map((message, i) => (
          <ChatBubble message={message} key={i} />
        ))}
        {showDynamicBubble && <ChatBubble message={dynamicMessage} />}
      </div>
    </div>
    // </div>
  );
};
