import { ChatProvider } from "../../contexts/ChatContext";
import { UserProvider } from "../../contexts/UserContext";
import { ChatContainer } from "../chatContainer/ChatContainer";
import ColorfulBg from '../../assets/images/ColorfulBg.jpeg';

export const AppContainer = () => {
  return (
    <div className="app" style={{backgroundImage: `url(${ColorfulBg})`}}>
      <UserProvider>
        <ChatProvider>
          <ChatContainer />
        </ChatProvider>
      </UserProvider>
    </div>
  );
};
